<template>
  <div>
    <header id="menubar">
        <div class="top-nav section section--inverted">
          <div class="d-flex container align-items-center">
            <div class="d-flex mr-auto justify-content-start"></div>
            <div class="d-flex ml-auto justify-content-end">
              <ul class="top-nav-links">
                <li class="top-nav-links-item social-item">
                  <a
                    href="https://www.facebook.com/MuscatMizziAdvocates"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="icon-facebook"
                  >
                    <i class="icon-facebook"></i>
                  </a>
                </li>
                <li class="divider">|</li>
                <li class="top-nav-links-item social-item">
                  <a
                    href="https://twitter.com/muscatmizzi"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="icon-twitter"
                  >
                    <i class="icon-twitter"></i>
                  </a>
                </li>
                <li class="divider">|</li>
                <li class="top-nav-links-item social-item">
                  <a
                    href="https://www.linkedin.com/company/muscat-mizzi-advocates"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="icon-linkedin-logo"
                  >
                    <i class="icon-linkedin-logo"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container desktop d-none d-md-block">
          <div class="row">
            <nav class="col-12 menu-content">
              <a  target="_parent" href="https://www.muscatmizzi.com" class="logo">
                <img
                  src="https://www.muscatmizzi.com/storage/media/JowmLiXIEZse38qGC6rLQEU6RiMQcGIrXYbnUiLo.png"
                  alt="logo"
                  title="Muscat Mizzi Advocates"
                  width="180"
                  height="64"
                />
              </a>
              <ul class="menuitems">
                <li class="active">
                  <a  target="_parent" href="https://www.muscatmizzi.com/" title="Home">
                    <span data-flip="Home" class="flip">Home</span>
                  </a>
                </li>
                <li class="active">
                  <a  target="_parent" href="https://www.muscatmizzi.com/firm" title="The Firm">
                    <span data-flip="The Firm" class="flip">The Firm</span>
                  </a>
                </li>
                <li class="active">
                  <a
                    href="https://www.muscatmizzi.com/practices"
                    title="Practices"
                  >
                    <span data-flip="Practices" class="flip">Practices</span>
                  </a>
                </li>
                <li class="active">
                  <a  target="_parent" href="https://www.muscatmizzi.com/insights" title="Insights">
                    <span data-flip="Insights" class="flip">Insights</span>
                  </a>
                </li>
                <li class="active">
                  <a  target="_parent" href="https://www.muscatmizzi.com/contact" title="Contact">
                    <span data-flip="Contact" class="flip">Contact</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="container mobile d-block d-md-none">
          <div class="row">
            <div class="col-12 menu-content">
              <a href="https://www.muscatmizzi.com" class="logo" target="_parent">
                <img
                  src="https://www.muscatmizzi.com/storage/media/JowmLiXIEZse38qGC6rLQEU6RiMQcGIrXYbnUiLo.png"
                  alt="logo"
                  title="Muscat Mizzi Advocates"
                  width="120"
                  height="40"
                />
              </a>
              <a
                role="button"
                @click="mobileMenuShown = !mobileMenuShown"
                aria-label="toggle-menu"
                class="toggle-menu"
              >
                <span class="icon-menu"></span> &nbsp;
              </a>
            </div>
          </div>
        </div>
        <div
          id="menu-hide"
          :class="`menu-overlay ${mobileMenuShown ? '' : 'menu-hide'}`"
        >
          <div class="container visible-xs">
            <div class="row">
              <nav class="col-12 text-center">
                <br />
                <div class="overlay-section">
                  <div class="overlay-section-heading">
                    <a
                      href="https://www.muscatmizzi.com/"
                      target="_parent"
                      title="Home"
                      >Home</a
                    >
                  </div>
                </div>
                <div class="overlay-section">
                  <div class="overlay-section-heading">
                    <a
                      href="https://www.muscatmizzi.com/firm"
                      target="_parent"
                      title="The Firm"
                      >The Firm</a
                    >
                  </div>
                </div>
                <div class="overlay-section">
                  <div class="overlay-section-heading">
                    <a
                      href="https://www.muscatmizzi.com/practices"
                      target="_parent"
                      title="Practices"
                      >Practices</a
                    >
                  </div>
                </div>
                <div class="overlay-section">
                  <div class="overlay-section-heading">
                    <a
                      href="https://www.muscatmizzi.com/insights"
                      target="_parent"
                      title="Insights"
                      >Insights</a
                    >
                  </div>
                </div>
                <div class="overlay-section">
                  <div class="overlay-section-heading">
                    <a
                      href="https://www.muscatmizzi.com/contact"
                      target="_parent"
                      title="Contact"
                      >Contact</a
                    >
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    <b-container>
      <div class="section section--padding no-underline">
        <div class="my-5 text-center">
          <h3 class="section-title section-title--underlined">My ARMS Fraud Calculator Results</h3>
        </div>
        <b-row class="mt-5 pt-5">
          <b-col sm>
            <div><h5 class="my-0">I paid</h5></div>
            <div>
              <h4 class="no-underline">€ {{ decodedResult[0] }}</h4>
            </div>

            <div>
              <h5 class="my-0">I should have paid</h5>
            </div>
            <div>
              <h4 class="no-underline font-weight-bold">
                € {{ decodedResult[1] }}
              </h4>
            </div>

            <div>
              <h5 class="my-0">Arms overcharged me</h5>
            </div>
            <div>
              <h4 class="no-underline font-weight-bold text-danger">
                € {{ decodedResult[2] }}
              </h4>
            </div>
          </b-col>
          <b-col sm>
            <div class="mb-2">
              This tool compares your actual bill against what ARMS has charged you. 
              If you consume more electricity in summer than you do in winter, you have likely been illegally overcharged. 
            </div>
            <div>
              To use the tool you will need 1 full year of your bills between 2015 and {{ computedGetFullYear }}. 
              You may have received them in the post or you can download them from the ARMS website.
            </div>
            <div>
              <a
                href="https://www.muscatmizzi.com/lab/arms"
                target="_parent"
                class="btn btn-std btn-primary h3 p-4"
                style="font-size: 1em; line-height: unset; height: unset;"
                >Check how much ARMS Defrauded you</a
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobileMenuShown: false,
    };
  },
  computed: {
    decodedResult() {
      const results = atob(this.$route.query.hash);
      return results.split(",");
    },
    computedDescription() {
      return `I used ARMS fraud calculator at www.muscatmizzi.com/arms-fraud. In 1 year, I paid
                ${this.decodedResult[0]}
                ', but I should have paid
                ${this.decodedResult[1]}
                .`;
    },
  },
  metaInfo() {
    return {
      title: this.computedDescription,
    };
  },
};
</script>
<style>
@font-face {
  font-family: Gotham;
  src: url("../assets/fonts/Gotham-Light.woff") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url("../assets/fonts/Gotham-Bold.woff") format("woff2");
  font-weight: 700;
}
body {
  font-family: Gotham;
  font-size: 16px;
  color: #333;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700 !important;
  color: #274b7b;
}
h4 {
  border-bottom: 2px solid #139cdc;
  font-weight: 700;
}
.no-underline {
  border-bottom: none;
}
#app {
  font-family: Gotham;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
p {
  font-weight: normal;
}
body {
}
body p,
body label {
  font-size: 16px;
}
.form-row {
  margin: 0 0 1rem 0;
}
body h4 {
  font-size: 32px;
}

body h3 {
  font-size: 36px;
}
body input {
  line-height: 3rem;
}
body .form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 200;
  color: #555;
  background-image: none;
  border: 1px solid #acacac;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
body button,
body .btn {
  border-radius: 0;
  background: #fff;
  border: 1px solid #274b7b;
  padding: 0 10px;
  line-height: 36px;
  height: 36px;
  color: #274b7b;
  font-size: 13px;
  font-weight: lighter;
  transition-duration: 0.1s;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.btn.btn-std {
  border-radius: 0;
  background: #274b7b;
  border: 1px solid #274b7b;
  padding: 0 10px;
  line-height: 36px;
  height: 36px;
  color: #fff;
  font-size: 13px;
  font-weight: lighter;
  transition-duration: 0.1s;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.btn.twitter {
  background-color: #1da1f2;
  border: none;
}
.btn.facebook {
  background-color: #4267b2;
  border: none;
}
body .btn:hover {
  background: #139cdc !important;
  border-color: #139cdc;
  color: #fff !important;
}
body .btn-primary:not(:disabled):not(.disabled):active,
body .btn-primary:not(:disabled):not(.disabled).active,
body .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #274b7b;
  border-color: #274b7b;
}

#menubar,
#menubar .mobile {
  z-index: 10;
}
#menubar {
  top: 0;
  width: 100%;
  background: #fff;
  position: fixed;
  border-bottom: 2px solid #139cdc;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#menubar .desktop .logo {
  position: absolute;
  display: block;
  font-size: 0;
  line-height: 0;
  height: 60px;
  left: 20px;
  top: 50%;
  transition-property: all;
  transition-duration: 0.4s;
  transform-origin: 0 0;
  transform: scale(1) translateY(-50%);
}
#menubar .desktop .logo img {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
#menubar .desktop ul.menuitems {
  margin: -19px 0 0;
  padding: 0;
  height: 36px;
  line-height: 36px;
  float: right;
  top: 50%;
  transition-duration: 1s;
  list-style: none;
  overflow: hidden;
}
#menubar .desktop ul.menuitems li {
  display: inline-block;
  margin: 0 20px;
  transition-duration: 0.25s;
  height: 100%;
  font-size: 1.3rem;
}
.section.section--inverted {
  background: #274b7b;
  color: #fff;
}
.top-nav > div .top-nav-links {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.top-nav > div .top-nav-links li {
  display: inline-block;
  color: #fff;
}
.top-nav > div .top-nav-links .divider {
  color: #fff;
  margin: 0 0.2rem;
}
#menubar {
  top: 0;

  width: 100%;

  z-index: 10;

  background: #fff;

  position: fixed;

  border-bottom: 2px solid #139cdc;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

#menubar .mobile {
  z-index: 10;
}

#menubar .mobile .menu-content {
  height: 50px;

  -webkit-transition-duration: 300ms;

  transition-duration: 300ms;
}

#menubar .mobile .logo {
  position: absolute;

  display: block;

  font-size: 0;

  line-height: 0;

  height: 40px;

  left: 20px;

  top: 50%;

  -webkit-transform: translateY(-50%);

  transform: translateY(-50%);
}

#menubar .mobile .logo img {
  height: 100%;

  object-fit: contain;
}

#menubar .mobile .toggle-menu {
  height: 100%;

  position: absolute;

  top: 0;

  right: 0;

  padding: 0 15px 0 40px;

  font-size: 22px !important;

  line-height: 50px !important;

  color: #333;
}

#menubar .mobile .toggle-menu .icon-menu {
  top: 4px;
}

.small-bar #menubar .desktop .menu-content {
  height: 50px;
}

.small-bar #menubar .desktop .logo {
  -webkit-transform: scale(0.6) translateY(-50%);

  transform: scale(0.6) translateY(-50%);
}

.small-bar #menubar .menu-overlay {
  top: 52px;
}

#menubar .desktop {
  overflow: hidden;

  z-index: 10;
}

#menubar .desktop .menu-content {
  height: 100px;

  -webkit-transition-duration: 300ms;

  transition-duration: 300ms;
}

#menubar .desktop .logo {
  position: absolute;

  display: block;

  font-size: 0;

  line-height: 0;

  height: 60px;

  left: 20px;

  top: 50%;

  -webkit-transition-property: all;

  transition-property: all;

  -webkit-transition-duration: 400ms;

  transition-duration: 400ms;

  -webkit-transform-origin: 0 0%;

  transform-origin: 0 0%;

  -webkit-transform: scale(1) translateY(-50%);

  transform: scale(1) translateY(-50%);
}

#menubar .desktop .logo img {
  height: 100%;

  object-fit: contain;
}

#menubar .desktop ul.menuitems {
  margin: -19px 0 0 0;

  padding: 0;

  height: 36px;

  line-height: 36px;

  float: right;

  top: 50%;

  -webkit-transition-duration: 1s;

  transition-duration: 1s;

  list-style: none;

  overflow: hidden;
}

#menubar .desktop ul.menuitems li {
  display: inline-block;

  margin: 0 20px;

  -webkit-transition-duration: 250ms;

  transition-duration: 250ms;

  height: 100%;

  font-size: 1.3rem;
}

#menubar .desktop ul.menuitems li.separator {
  color: #333;
}

#menubar .desktop ul.menuitems li.search {
  width: 36px;

  height: 36px;

  margin: 0;

  display: inline-block;

  font-size: 0;
}

#menubar .desktop ul.menuitems li.search .searchButton {
  text-align: center;

  margin: 0;

  width: 100%;

  height: 36px;

  -webkit-transition-property: background;

  transition-property: background;

  -webkit-transition-duration: 200ms;

  transition-duration: 200ms;

  -webkit-transition-delay: 400ms;

  transition-delay: 400ms;

  z-index: 10;
}

#menubar .desktop ul.menuitems li.search .searchButton .icon-magnifier {
  -webkit-transition-property: color;

  transition-property: color;

  -webkit-transition-duration: 200ms;

  transition-duration: 200ms;

  -webkit-transition-delay: 400ms;

  transition-delay: 400ms;

  color: #274b7b;

  top: 5px;

  font-size: 18px;

  margin-bottom: -10px;
}

#menubar .desktop ul.menuitems li.search form {
  position: absolute;

  pointer-events: none;

  right: 100%;

  top: 0;

  overflow: hidden;

  height: 36px;

  z-index: 5;
}

#menubar .desktop ul.menuitems li.search form input {
  width: 390px;

  height: 36px;

  box-sizing: border-box !important;

  border: 1px solid #139cdc;

  border-right-width: 0;

  padding: 2px 10px 0 10px;

  opacity: 0;

  -webkit-transition-property: opacity, -webkit-transform;

  transition-property: opacity, -webkit-transform;

  transition-property: transform, opacity;

  transition-property: transform, opacity, -webkit-transform;

  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  -webkit-transition-duration: 400ms !important;

  transition-duration: 400ms !important;

  -webkit-transform: translateX(390px);

  transform: translateX(390px);
}

#menubar .desktop ul.menuitems li a {
  display: inline-block;

  font-weight: normal;

  line-height: 30px;

  color: #274b7b !important;

  padding: 0;

  margin: 0;

  height: 30px;
}

#menubar .desktop ul.menuitems li a:hover .flip {
  -webkit-transform: translateY(-100%);

  transform: translateY(-100%);
}

#menubar .desktop ul.menuitems li a .flip {
  display: block;

  -webkit-transition-duration: 300ms;

  transition-duration: 300ms;

  -webkit-transition-property: opacity, -webkit-transform;

  transition-property: opacity, -webkit-transform;

  transition-property: transform, opacity;

  transition-property: transform, opacity, -webkit-transform;

  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);

  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

#menubar .desktop ul.menuitems li a .flip::after {
  color: #139cdc;

  position: absolute;

  top: 100%;

  left: 0;

  content: attr(data-flip);
}

#menubar .desktop ul.menuitems li a::after,
#menubar .desktop ul.menuitems li a::before {
  content: " ";

  font-size: 0;

  height: 2px;

  position: absolute;

  bottom: -0px;

  left: -3px;

  right: -3px;

  background: #139cdc;

  opacity: 0;

  -webkit-transition-property: opacity, -webkit-transform;

  transition-property: opacity, -webkit-transform;

  transition-property: transform, opacity;

  transition-property: transform, opacity, -webkit-transform;

  -webkit-transition-duration: 0s, 150ms;

  transition-duration: 0s, 150ms;

  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

  -webkit-transform-origin: 0 0;

  transform-origin: 0 0;

  -webkit-transform: scaleX(0);

  transform: scaleX(0);
}

#menubar .desktop ul.menuitems li a::after {
  -webkit-transition-duration: 100ms, 150ms;

  transition-duration: 100ms, 150ms;
}

#menubar .desktop ul.menuitems li a.underlined::before,
#menubar .desktop ul.menuitems li a.underlined-router::before {
  opacity: 1;

  -webkit-transform: scaleX(1);

  transform: scaleX(1);
}

#menubar .desktop ul.menuitems li a.charge-underline:hover::before {
  opacity: 0;
}

#menubar .desktop ul.menuitems li a.charge-underline:hover::after {
  opacity: 1;

  -webkit-transition-duration: 2500ms, 150ms;

  transition-duration: 2500ms, 150ms;

  -webkit-transform: scaleX(1);

  transform: scaleX(1);
}

#menubar .desktop ul.menuitems.search-visible li:not(.search) {
  opacity: 0;

  pointer-events: none;
}

#menubar .desktop ul.menuitems.search-visible .search .searchButton {
  -webkit-transition-delay: 0s;

  transition-delay: 0s;

  background: #274b7b;
}

#menubar
  .desktop
  ul.menuitems.search-visible
  .search
  .searchButton
  .icon-magnifier {
  color: #fff;

  -webkit-transition-delay: 0s;

  transition-delay: 0s;
}

#menubar .desktop ul.menuitems.search-visible .search form {
  pointer-events: auto;
}

#menubar .desktop ul.menuitems.search-visible .search form input {
  opacity: 1;

  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  -webkit-transform: translateX(0px);

  transform: translateX(0px);
}

#menubar .menu-overlay {
  position: absolute;

  width: 100%;

  right: 0;

  top: 102px;

  height: calc(100vh - 50px - 2px);

  background: rgba(255, 255, 255, 0.95);

  -webkit-transition-property: opacity, top, -webkit-transform;

  transition-property: opacity, top, -webkit-transform;

  transition-property: transform, opacity, top;

  transition-property: transform, opacity, top, -webkit-transform;

  -webkit-transition-duration: 300ms;

  transition-duration: 300ms;

  -webkit-transform: scale(1);

  transform: scale(1);

  padding-top: 20px;

  z-index: 5;

  overflow-y: auto;
}

@media (max-width: 767px) {
  #menubar .menu-overlay {
    top: 52px !important;
  }
}

#menubar .menu-overlay.ng-hide {
  -webkit-transform: scale(0.9);

  transform: scale(0.9);
}

#menubar .menu-overlay .close-overlay:hover {
  color: #274b7b;
}

#menubar .menu-overlay .logo {
  display: block;

  margin: 80px auto 0 auto;

  width: 62px;
}

@media (max-width: 767px) {
  #menubar .menu-overlay .logo {
    margin-top: 40px;

    width: 50px;
  }
}

#menubar
  .menu-overlay
  .overlay-section.overlay-section--active
  .overlay-section-heading::before,
#menubar
  .menu-overlay
  .overlay-section.overlay-section--active
  .overlay-section-heading::after {
  width: calc(50% - 9px);
}

#menubar
  .menu-overlay
  .overlay-section.overlay-section--active
  .overlay-section-heading
  .triangle {
  display: block;
}

#menubar
  .menu-overlay
  .overlay-section.overlay-section--active
  .overlay-section-content {
  display: block;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading {
  line-height: 54px;

  text-align: center;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading a {
  font-size: 18px;

  font-weight: normal;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading .triangle {
  display: none;

  position: absolute;

  bottom: -7px;

  left: 50%;

  margin-left: -7px;

  width: 14px;

  height: 14px;

  -webkit-transform: rotate(45deg);

  transform: rotate(45deg);

  border-right: 1px solid #acacac;

  border-bottom: 1px solid #acacac;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading::before,
#menubar .menu-overlay .overlay-section .overlay-section-heading::after {
  position: absolute;

  content: " ";

  font-size: 0;

  width: 50%;

  bottom: 0;

  height: 1px;

  background: #acacac;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading::before {
  left: 0;
}

#menubar .menu-overlay .overlay-section .overlay-section-heading::after {
  right: 0;
}

#menubar .menu-overlay .overlay-section .overlay-section-content {
  padding: 30px 0 0 0;

  border-bottom: 1px solid #acacac;

  display: none;
}

#menubar
  .menu-overlay
  .overlay-section
  .overlay-section-content
  .overlay-navlist {
  display: inline-block;

  margin-bottom: 30px;

  width: 20%;

  float: left;
}

@media (max-width: 991px) {
  #menubar
    .menu-overlay
    .overlay-section
    .overlay-section-content
    .overlay-navlist {
    width: 33.33333%;
  }
}

#menubar
  .menu-overlay
  .overlay-section
  .overlay-section-content
  .overlay-navlist
  a.category {
  font-size: 18px;

  color: #139cdc;
}

#menubar
  .menu-overlay
  .overlay-section
  .overlay-section-content
  .overlay-navlist
  a {
  display: block;

  color: #333;
}

#menubar
  .menu-overlay
  .overlay-section
  .overlay-section-content
  .overlay-navlist
  a:hover {
  color: #274b7b;
}
</style>